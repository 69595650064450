import classNames from "classnames";
import { isEmpty } from "ramda";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Column } from "react-table-7";

import { Button, Icon } from "pattern-library";
import { DEFAULT_PAGE_SIZE, Table } from "pattern-library/elements/table7";

import { makeSafeForCSS } from "common/utils";
import { filterProcessor } from "modules/utils/table";

import { VariantType } from "../common-types";

import styles from "./CommonContainer.module.scss";
import { useSplitDriverPassengerVariants } from "./useSplitDriverPassengerVariants";
import { getCellProps, isDriver } from "./utils";

export const FILTER_PLACEHOLDER = "Filter by Gene...";

interface CommonContainerProps {
  data: VariantType[];
  columns: Column[];
  titleSegment: string;
  className?: string;
  getRowProps: any;
  defaultSortBy: SortBy[];
  variantToBrowse?: VariantType | null;
  genomeBrowserNode?: React.ReactNode;
  handleBackToVariantReclassification?: () => void;
}

interface SortBy {
  id: string;
  desc?: boolean;
}

interface ReactTable7RefPartial {
  setSortBy: (sortBy: SortBy[]) => void;
  setPageSize: (pageSize: number) => void;
}

const filter = filterProcessor(
  ["gene", "gene_a_symbol", "gene_b_symbol"],
  null
);

export const CommonContainer: FC<CommonContainerProps> = ({
  titleSegment,
  columns,
  getRowProps,
  data,
  className,
  defaultSortBy,
  variantToBrowse,
  genomeBrowserNode,
  handleBackToVariantReclassification,
}) => {
  const [filterValue, setFilterValue] = useState<string>("");
  const [filteredVariants, setFilteredVariants] = useState<VariantType[]>([]);
  const [showPassengers, setShowPassengers] = useState(true);

  const { drivers, passengers } =
    useSplitDriverPassengerVariants(filteredVariants);

  const driverTableRef = useRef<ReactTable7RefPartial>(null);
  const passengerTableRef = useRef<ReactTable7RefPartial>(null);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      setFilteredVariants(filter(data, filterValue));
    }
  }, [data, filterValue, setFilteredVariants]);

  const onFilterChange = useCallback(
    ({ filter = "" }) => {
      setFilterValue(filter);
    },
    [setFilterValue]
  );

  const handleSortDrivers = useCallback((sortedColumns: SortBy[]) => {
    if (passengerTableRef.current?.setSortBy) {
      passengerTableRef.current.setSortBy(sortedColumns);
    }
  }, []);

  const handleSortPassengers = useCallback((sortedColumns: SortBy[]) => {
    if (driverTableRef.current?.setSortBy) {
      driverTableRef.current.setSortBy(sortedColumns);
    }
  }, []);

  const handleBackToReclassification = useCallback(
    e => {
      e.stopPropagation();
      handleBackToVariantReclassification?.();
    },
    [handleBackToVariantReclassification]
  );
  const driverBeingBrowsed = variantToBrowse && isDriver(variantToBrowse);

  const handleHidePassengers = () => {
    setShowPassengers(false);
    driverTableRef.current?.setPageSize?.(20);
  };

  const handleShowPassengers = () => {
    setShowPassengers(true);
    driverTableRef.current?.setPageSize?.(10);
    passengerTableRef.current?.setPageSize?.(10);
  };

  return (
    <>
      <Table
        id={`react-oncology-drivers-${makeSafeForCSS(titleSegment)}`}
        className={classNames(
          className,
          driverBeingBrowsed === false && "hidden"
        )}
        showTitleInfo={!variantToBrowse}
        title={
          driverBeingBrowsed === true
            ? "Browsing driver variant"
            : `Driver ${titleSegment}`
        }
        fetchData={onFilterChange}
        enableFilter={!variantToBrowse}
        columns={columns}
        data={drivers}
        showPagination={!variantToBrowse && drivers.length > DEFAULT_PAGE_SIZE}
        autoResetPage={false}
        filterPlaceholder={FILTER_PLACEHOLDER}
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        sortBy={defaultSortBy}
        autoResetSortBy={false}
        onSort={handleSortDrivers}
        tableRef={driverTableRef}
        defaultPageSize={showPassengers ? 10 : 20}
      />
      {!variantToBrowse && (
        <Button
          context="primary"
          className={styles["action-button-with-icon"]}
          onClick={showPassengers ? handleHidePassengers : handleShowPassengers}
          data-testid="show-hide-passengers-button"
        >
          <Icon
            type={showPassengers ? "minus" : "plus"}
            className={styles.icon}
          />
          {showPassengers ? "Hide passengers" : "Show passengers"}
        </Button>
      )}
      <Table
        id={`react-oncology-passengers-${makeSafeForCSS(titleSegment)}`}
        className={classNames(
          className,
          (driverBeingBrowsed === true || !showPassengers) && "hidden"
        )}
        showTitleInfo={!variantToBrowse}
        title={
          driverBeingBrowsed === false
            ? "Browsing passenger variant"
            : `Passenger ${titleSegment}`
        }
        columns={columns}
        data={passengers}
        showPagination={
          !variantToBrowse && passengers.length > DEFAULT_PAGE_SIZE
        }
        autoResetPage={false}
        getRowProps={getRowProps}
        getCellProps={getCellProps}
        sortBy={defaultSortBy}
        autoResetSortBy={false}
        onSort={handleSortPassengers}
        tableRef={passengerTableRef}
      />
      {variantToBrowse && (
        <div>
          <Button
            context="primary"
            className={styles["action-button-with-icon"]}
            onClick={handleBackToReclassification}
            data-testid="back-to-reclassification-button"
          >
            <span
              className={classNames(
                "caret",
                styles.icon,
                styles["reversed-caret"]
              )}
            />
            Back to variants
          </Button>
          {genomeBrowserNode}
        </div>
      )}
    </>
  );
};
