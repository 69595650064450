import React, { useMemo } from "react";

import GenoverseBrowser from "modules/genoverse/GenoverseBrowser/GenoverseBrowser";
import { GenoverseConfig } from "modules/genoverse/GenoverseBrowser/GenoverseBrowser.types";
import { OncologyGeneTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologyGeneTrackWrapper";
import { OncologyPatientSVTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologyPatientSVTrackWrapper";
import { OncologySequenceAlignmentTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologySequenceAlignmentTrackWrapper";

import { adjustEndPosition, adjustStartPosition } from "./utils";

type Props = {
  patientId: number;
  startPosition: number;
  endPosition: number;
  chrom: string;
};

export const OncologyCNVBrowser: React.FC<Props> = ({
  patientId,
  startPosition,
  endPosition,
  chrom,
}) => {
  const config = useMemo<GenoverseConfig>(
    () => ({
      genome: "grch38",
      chr: chrom.replaceAll("chr", ""),
      patientId,
      // These "zoom" position adjustments are reused from snvs and not entirely sure what's behind it for snvs
      // (check comment for constant used) but in this case it allows user to initially see the corners of
      // cnv gain/loss feature corners instead of aligning with genoverse browser corners, so it suits the purpose
      start: adjustStartPosition(startPosition),
      end: adjustEndPosition(endPosition),
      // initially shown tracks
      tracks: ["oncologyPatientSV", "oncologyGene"],
      saveKey: "congenicaGenoverse-oncology-cnv",
      saveable: true,
      storageType: "localStorage",
    }),
    [patientId, chrom, startPosition, endPosition]
  );

  const tracksLibrary = useMemo(
    () => [
      OncologyPatientSVTrackWrapper(config),
      OncologyGeneTrackWrapper(config),
      OncologySequenceAlignmentTrackWrapper(config),
    ],
    [config]
  );

  return (
    <GenoverseBrowser
      className="congenica-genoverse-browser"
      config={{
        ...config,
        tracksLibrary,
      }}
    />
  );
};
