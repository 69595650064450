import GenoverseTracks from "modules/genoverse/GenoverseTracks";

const { SequenceAlignment } = GenoverseTracks;

export const OncologySequenceAlignmentTrackWrapper = config => {
  const sequence_alignment = SequenceAlignment(config).extend({
    trackKey: "oncologySequenceAlignment",
    click: () => false, // Disable feature clicks
    populateMenu() {
      return false;
    },
    200: false,
  });
  // remove default region thresholds.
  delete sequence_alignment[2000];
  delete sequence_alignment[10000];
  return sequence_alignment;
};
