import { useMemo } from "react";

import { isDriver } from "modules/oncologyExplore/components/utils";

import { VariantType } from "../common-types";

type SplitDriverPassengerVariants = {
  drivers: VariantType[];
  passengers: VariantType[];
};

export const useSplitDriverPassengerVariants = (
  variants: VariantType[]
): SplitDriverPassengerVariants =>
  useMemo(
    () =>
      variants.reduce(
        (acc, variant) => {
          const driver = isDriver(variant);

          if (driver) {
            acc.drivers.push(variant);
          } else {
            acc.passengers.push(variant);
          }

          return acc;
        },
        { drivers: [] as VariantType[], passengers: [] as VariantType[] }
      ),
    [variants]
  );
