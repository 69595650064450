import classNames from "classnames";
import PropTypes from "prop-types";
import { equals, isEmpty, isNil } from "ramda";
import React, {
  Fragment,
  memo,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import {
  useExpanded,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table-7";

import {
  DataTableFooter as DataTableFooterDefault,
  LoadingComponent as LoadingComponentDefault,
  NoDataComponent as NoDataComponentDefault,
  TableComponent as TableComponentDefault,
  TbodyComponent as TbodyComponentDefault,
  TdComponent as TdComponentDefault,
  ThComponent as ThComponentDefault,
  TheadComponent as TheadComponentDefault,
  TrComponent as TrComponentDefault,
} from "pattern-library";
import DraggableTrComponent from "pattern-library/elements/table7/DraggableTrComponent";

import notIsNil from "modules/utils/notIsNil";

import FilterField from "./FilterField";
import { useTableSettings } from "./useTableSettings";

export const DEFAULT_PAGE_SIZE = 10;

export const useTitleInfo = (
  showPagination,
  data,
  pageIndex,
  pageSize,
  totalCount = -1
) => {
  const { length: localCount } = data;
  const itemCount = totalCount === -1 ? localCount : totalCount;
  let fromNumber = itemCount ? 1 : 0;
  if (showPagination && itemCount) {
    fromNumber = pageIndex * pageSize + 1;
  }
  const toNumber = showPagination
    ? Math.min((pageIndex + 1) * pageSize, itemCount)
    : itemCount;

  return [itemCount, fromNumber, toNumber];
};

const noop = () => {};

const Table = memo(
  ({
    columns,
    data,
    setTableFieldValue,
    defaultPageSize,
    showPagination,
    pageSizeOptions,
    fetchData,
    className,
    TableComponent = TableComponentDefault,
    TheadComponent = TheadComponentDefault,
    TbodyComponent = TbodyComponentDefault,
    TrGroupComponent,
    TrComponent = TrComponentDefault,
    ThComponent = ThComponentDefault,
    TdComponent = TdComponentDefault,
    LoadingComponent = LoadingComponentDefault,
    NoDataComponent = NoDataComponentDefault,
    DataTableFooter = DataTableFooterDefault,
    CustomFilterComponent,
    noDataText,
    loading,
    loadingText,
    getRowProps,
    getCellProps,
    sortBy,
    autoResetSortBy,

    totalCount = data.length,
    onSort,
    manualSortBy = false,
    title,
    titleHelpText,
    enableFilter = false,
    filterPlaceholder,
    manualPagination = false,
    showTitleInfo = false,
    renderRowSubComponent,
    autoResetExpanded = true,
    moveRow,
    onDropRow,
    initialPageIndex = 0,
    onPageSizeChange = noop,
    id,
    onPageChange = noop,
    tableRef,
    ...rest
  }) => {
    const [controlledPageCount, setControlledPageCount] = useState(0);

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      page,
      pageCount,
      gotoPage,
      setPageSize,
      state: { pageIndex, pageSize, sortBy: sortedBy, globalFilter },
      setGlobalFilter,
      prepareRow,
      visibleColumns,
      toggleSortBy,
      manualGlobalFilter,
      setSortBy,
    } = useTable(
      {
        columns,
        data,
        initialState: {
          pageSize: defaultPageSize,
          sortBy,
        },
        manualSortBy,
        autoResetGlobalFilter: false,
        manualGlobalFilter: true,
        manualPagination,
        pageCount: controlledPageCount,
        autoResetExpanded,
        autoResetSortBy,
        ...rest,
      },
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    );
    const records = showPagination ? page : rows;
    const [prevSettings, currentSettings] = useTableSettings(
      pageIndex,
      pageSize,
      sortedBy,
      globalFilter
    );
    // Listen for changes in pagination and use the state to fetch our new data
    useEffect(() => {
      if (fetchData && !equals(prevSettings, currentSettings)) {
        // API conflict: to reset a page when filtering, manualPagination needs to be false
        // whereas server-side pagination needs manualPagination=true
        // see https://react-table.tanstack.com/docs/api/usePagination#table-options.
        // The conditions below are a workaround to reset the page and prevent double fetch on filtering
        const { pageIndex, filter } = currentSettings;
        if (prevSettings.filter !== filter) {
          if (isNil(filter) || isEmpty(filter)) {
            gotoPage(initialPageIndex);
          } else if (pageIndex !== 0) {
            gotoPage(0);
            return;
          }
        }
        fetchData(currentSettings);
      }
    }, [
      fetchData,
      gotoPage,
      prevSettings,
      currentSettings,
      pageIndex,
      initialPageIndex,
    ]);

    useEffect(() => {
      setControlledPageCount(Math.ceil(totalCount / pageSize));
      onPageSizeChange(pageSize);
    }, [pageSize, totalCount, setControlledPageCount, onPageSizeChange]);

    useEffect(() => {
      gotoPage(initialPageIndex);
    }, [gotoPage, initialPageIndex]);

    useImperativeHandle(tableRef, () => ({
      resetPage: () => gotoPage(0),
      sortedBy,
      toggleSortBy,
      setSortBy,
      setPageSize,
    }));

    useEffect(() => {
      onPageChange();
    }, [onPageChange, pageIndex]);

    useEffect(() => {
      if (onSort) {
        onSort(sortedBy);
      }
    }, [onSort, sortedBy]);

    const filteredTotalCount = manualGlobalFilter ? totalCount : rows.length;

    const [itemCount, fromNumber, toNumber] = useTitleInfo(
      showPagination,
      data,
      pageIndex,
      pageSize,
      filteredTotalCount
    );

    return (
      <div
        id={id}
        data-testid={id}
        className={classNames("table-container", className)}
      >
        <div className="dataTables_info">
          {title && (
            <h4>
              {title}
              {showTitleInfo && (
                <span className="ws-nowrap">
                  {`: ${fromNumber} to ${toNumber} of ${itemCount}`}
                </span>
              )}
            </h4>
          )}
          {enableFilter && (
            <FilterField
              placeholder={filterPlaceholder}
              onFilterChange={setGlobalFilter}
            />
          )}
          {CustomFilterComponent}
          {titleHelpText && (
            <div className="title-help-text">
              <small className="text-muted">{titleHelpText}</small>
            </div>
          )}
        </div>
        <TableComponent {...getTableProps()}>
          <TheadComponent>
            {headerGroups.map(headerGroup => (
              <TrComponent {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <ThComponent
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classNames(
                      "table-th",
                      "-cursor-pointer",
                      {
                        "-sort-asc": column.isSorted && !column.isSortedDesc,
                        "-sort-desc": column.isSorted && column.isSortedDesc,
                      },
                      column.headerClassName
                    )}
                    style={{
                      ...column.getHeaderProps(column.getSortByToggleProps())
                        .style,
                      ...column.headerStyle,
                    }}
                  >
                    {column.render("Header")}
                  </ThComponent>
                ))}
              </TrComponent>
            ))}
          </TheadComponent>
          <TbodyComponent {...getTableBodyProps()}>
            {records.map((row, index) => {
              prepareRow(row);
              const Row =
                moveRow && onDropRow ? DraggableTrComponent : TrComponent;
              const rowProps =
                moveRow && onDropRow
                  ? {
                      moveRow,
                      onDropRow,
                    }
                  : {};
              return (
                <Fragment key={`row-${row.id}`}>
                  <Row
                    {...row.getRowProps(getRowProps)}
                    row={row}
                    index={index}
                    {...rowProps}
                  >
                    {row.cells.map(cell => (
                      <TdComponent
                        // Default props from react-table
                        {...cell.getCellProps()}
                        // Custom props that we pass
                        {...cell.getCellProps(getCellProps)}
                      >
                        {cell.render("Cell")}
                      </TdComponent>
                    ))}
                  </Row>

                  {notIsNil(renderRowSubComponent) && row.isExpanded && (
                    <TrComponent>
                      <TdComponent colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </TdComponent>
                    </TrComponent>
                  )}
                </Fragment>
              );
            })}
          </TbodyComponent>
        </TableComponent>
        {showPagination && (
          <div className="pagination-bottom">
            <DataTableFooter
              page={pageIndex}
              pages={pageCount}
              data={data}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              showPageSizeOptions
              onPageChange={gotoPage}
              onPageSizeChange={setPageSize}
              rowsText="rows"
              totalCount={filteredTotalCount}
            />
          </div>
        )}

        {data.length === 0 && !loading && (
          <NoDataComponent>{noDataText}</NoDataComponent>
        )}
        <LoadingComponent loading={loading} loadingText={loadingText} />
      </div>
    );
  }
);

Table.propTypes = {
  /**
   * The core columns configuration object for the entire table.
   * Supports nested columns arrays via the column's columns key,
   * eg. [{ Header: 'My Group', columns: [...] }]
   *
   * Must be memoized
   */
  columns: PropTypes.array.isRequired,
  /**
   * The data array that you want to display on the table.
   *
   * Must be memoized
   */
  data: PropTypes.array.isRequired,
  /**
   * ID to add to the main table element
   *
   * Can be used to assist with QA automated tests and running legacy JavaScript on table elements
   */
  id: PropTypes.string,
  defaultPageSize: PropTypes.number,
  showPagination: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  fetchData: PropTypes.func,
  className: PropTypes.string,
  TableComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  TbodyComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  TrGroupComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  TrComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  ThComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  TdComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  LoadingComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  NoDataComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  DataTableFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  CustomFilterComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
  ]),
  noDataText: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  getRowProps: PropTypes.func,
  getCellProps: PropTypes.func,
  title: PropTypes.string,
  enableFilter: PropTypes.bool,
  filterPlaceholder: PropTypes.string,
  sortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    })
  ),
  showTitleInfo: PropTypes.bool,
  moveRow: PropTypes.func,
  onDropRow: PropTypes.func,
  autoResetSortBy: PropTypes.bool,
};

Table.defaultProps = {
  TableComponent: TableComponentDefault,
  TheadComponent: TheadComponentDefault,
  TbodyComponent: TbodyComponentDefault,
  TrGroupComponent: ({ children }) => children,
  TrComponent: TrComponentDefault,
  ThComponent: ThComponentDefault,
  TdComponent: TdComponentDefault,
  LoadingComponent: LoadingComponentDefault,
  NoDataComponent: NoDataComponentDefault,
  DataTableFooter: DataTableFooterDefault,
  showPagination: false,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: [5, 10, 20, 30],
  noDataText: "No data available in table",
  loading: false,
  loadingText: "Loading...",
  className: "",
  sortBy: [],
  showTitleInfo: false,
  autoResetSortBy: true,
};

Table.displayName = "Table";

export default Table;
