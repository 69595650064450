import React, { FC, useMemo } from "react";

import { ComposableTabs, Tab } from "pattern-library";

import { VariantDriverClassifications } from "modules/oncologyExplore/common-types";
import { ReclassifiedCounts } from "modules/oncologyExplore/components/ReclassifiedCounts";
import {
  COPY_NUMBER_VARIANTS_TAB,
  RNA_FUSION_VARIANTS_TAB,
  SEQUENCE_VARIANTS_TAB,
} from "modules/oncologyExplore/constants";
import {
  OncologyExploreCopyNumberVariants,
  OncologyExploreRnaFusionVariants,
  OncologyExploreSequenceVariants,
} from "modules/oncologyExplore/index";
import { getReclassifiedVariantsCounts } from "modules/oncologyExplore/utils";

import { OncologyCNV } from "types/CNV";

interface Props {
  patientId: number;
  reclassificationInProgress: boolean;
  snvs: OncologySNV[];
  cnvs: OncologyCNV[];
  fusions: OncologyFusion[];
  snvDriverClassifications: VariantDriverClassifications;
  cnvDriverClassifications: VariantDriverClassifications;
  fusionsDriverClassifications: VariantDriverClassifications;
  setSnvDriverClassifications: React.Dispatch<
    React.SetStateAction<VariantDriverClassifications>
  >;
  setFusionsDriverClassifications: React.Dispatch<
    React.SetStateAction<VariantDriverClassifications>
  >;
  setCnvDriverClassifications: React.Dispatch<
    React.SetStateAction<VariantDriverClassifications>
  >;
  setGenomeBrowserShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const OncologyVariantTabs: FC<Props> = ({
  patientId,
  reclassificationInProgress,
  snvs,
  cnvs,
  fusions,
  snvDriverClassifications,
  cnvDriverClassifications,
  fusionsDriverClassifications,
  setSnvDriverClassifications,
  setFusionsDriverClassifications,
  setCnvDriverClassifications,
  setGenomeBrowserShown,
}) => {
  const reclassifiedSNVCounts = useMemo(
    () => getReclassifiedVariantsCounts(snvs, snvDriverClassifications),
    [snvs, snvDriverClassifications]
  );
  const reclassifiedCNVCounts = useMemo(
    () => getReclassifiedVariantsCounts(cnvs, cnvDriverClassifications),
    [cnvs, cnvDriverClassifications]
  );
  const reclassifiedFusionCounts = useMemo(
    () => getReclassifiedVariantsCounts(fusions, fusionsDriverClassifications),
    [fusions, fusionsDriverClassifications]
  );

  const reclassificationsPresent = useMemo(
    () =>
      Boolean(
        reclassifiedSNVCounts.reclassifiedAsDriverCount ||
          reclassifiedSNVCounts.reclassifiedAsPassengerCount ||
          reclassifiedCNVCounts.reclassifiedAsDriverCount ||
          reclassifiedCNVCounts.reclassifiedAsPassengerCount ||
          reclassifiedFusionCounts.reclassifiedAsDriverCount ||
          reclassifiedFusionCounts.reclassifiedAsPassengerCount
      ),
    [reclassifiedSNVCounts, reclassifiedCNVCounts, reclassifiedFusionCounts]
  );

  return (
    <ComposableTabs navStyle defaultActive={SEQUENCE_VARIANTS_TAB}>
      <Tab
        className="pull-right"
        name={RNA_FUSION_VARIANTS_TAB}
        info={
          reclassificationsPresent && (
            <ReclassifiedCounts reclassifiedCounts={reclassifiedFusionCounts} />
          )
        }
      >
        <OncologyExploreRnaFusionVariants
          reclassificationInProgress={reclassificationInProgress}
          data={fusions}
          variantDriverClassifications={fusionsDriverClassifications}
          setVariantDriverClassifications={setFusionsDriverClassifications}
        />
      </Tab>
      <Tab
        className="pull-right"
        name={COPY_NUMBER_VARIANTS_TAB}
        info={
          reclassificationsPresent && (
            <ReclassifiedCounts reclassifiedCounts={reclassifiedCNVCounts} />
          )
        }
      >
        <OncologyExploreCopyNumberVariants
          patientId={patientId}
          reclassificationInProgress={reclassificationInProgress}
          data={cnvs}
          variantDriverClassifications={cnvDriverClassifications}
          setVariantDriverClassifications={setCnvDriverClassifications}
          setGenomeBrowserShown={setGenomeBrowserShown}
        />
      </Tab>
      <Tab
        className="pull-right"
        name={SEQUENCE_VARIANTS_TAB}
        info={
          reclassificationsPresent && (
            <ReclassifiedCounts reclassifiedCounts={reclassifiedSNVCounts} />
          )
        }
      >
        <OncologyExploreSequenceVariants
          patientId={patientId}
          reclassificationInProgress={reclassificationInProgress}
          data={snvs}
          variantDriverClassifications={snvDriverClassifications}
          setVariantDriverClassifications={setSnvDriverClassifications}
          setGenomeBrowserShown={setGenomeBrowserShown}
        />
      </Tab>
    </ComposableTabs>
  );
};

export default OncologyVariantTabs;
