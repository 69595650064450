import React, { useMemo } from "react";

import GenoverseBrowser from "modules/genoverse/GenoverseBrowser/GenoverseBrowser";
import { GenoverseConfig } from "modules/genoverse/GenoverseBrowser/GenoverseBrowser.types";
import { OncologyGeneTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologyGeneTrackWrapper";
import { OncologyPatientSNVTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologyPatientSNVTrackWrapper";
import { OncologySequenceAlignmentTrackWrapper } from "modules/oncologyExplore/genoverse/tracks/OncologySequenceAlignmentTrackWrapper";
import {
  adjustEndPosition,
  adjustStartPosition,
} from "modules/oncologyExplore/genoverse/utils";

type Props = {
  patientId: number;
  pos: number;
  chrom: string;
};

export const OncologySNVBrowser: React.FC<Props> = ({
  patientId,
  pos,
  chrom,
}) => {
  const config = useMemo<GenoverseConfig>(
    () => ({
      genome: "grch38",
      chr: chrom.replaceAll("chr", ""),
      patientId,
      start: adjustStartPosition(pos),
      end: adjustEndPosition(pos),
      // initially shown tracks
      tracks: ["oncologyPatientSNV", "oncologyGene"],
      saveKey: "congenicaGenoverse-oncology-snv",
      saveable: true,
      storageType: "localStorage",
    }),
    [patientId, chrom, pos]
  );

  const tracksLibrary = useMemo(
    () => [
      OncologyPatientSNVTrackWrapper(config),
      OncologyGeneTrackWrapper(config),
      OncologySequenceAlignmentTrackWrapper(config),
    ],
    [config]
  );

  return (
    <GenoverseBrowser
      className="congenica-genoverse-browser"
      config={{
        ...config,
        tracksLibrary,
      }}
    />
  );
};
