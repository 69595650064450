export function commaFormatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const thresh = 1000;
const units = ["kbp", "Mbp", "Gbp"];

export function formatLength(length) {
  if (Math.abs(length) < thresh) {
    return length + "bp";
  }
  let u = -1;
  do {
    length /= thresh;
    ++u;
  } while (Math.abs(length) >= thresh && u < units.length - 1);
  return length.toFixed(1) + "" + units[u];
}

export const toDecimalPlaces = (
  item: string | number,
  decimalPlaces: number = 2,
  roundingMode: "up" | "down" = "up"
) => {
  let number = Number.parseFloat(item);
  if (isNaN(number)) {
    return item;
  }

  if (roundingMode === "down") {
    /*
     simulate rounding down as follows, e.g. 123.456 to 2dp is calculated as
     123.456 * 100 = 12345.60
     Math.floor(12345.60) === 12345.0
     12345 / 100 === 123.45

     This mode rounds away from zero for negative numbers e.g. 
     toDecimalPlaces("-1234.56", 1, "down") === "-1234.6"
     */
    const factor = 10 ** decimalPlaces;
    number = Math.floor(number * factor) / factor;
  }

  // toFixed rounds up
  return number.toFixed(decimalPlaces);
};

export const toPercentage = (value, precision = 2) =>
  (parseFloat(value) * 100).toFixed(precision);
