import { useMemo, ReactNode } from "react";

import { Pane, Loading, Alert, Download } from "pattern-library";

import styles from "./OncologyPatientOverview.module.scss";

import congenicaApi from "api/congenica-api";

const { useGetOncologyPatientDataQuery } = congenicaApi;

interface PatientOverviewEntry {
  id: string;
  key: ReactNode;
  value: ReactNode;
}

interface Props {
  patientId: number;
}

const getMetadataFieldValue = (
  metadata: OncologyPatientDataMetadataEntry[],
  metadataFieldCode: string
): string | undefined =>
  metadata?.find(({ code }) => code === metadataFieldCode)?.value;

const valueOrNA = (value: any) => value ?? "N/A";

const OncologyPatientOverview = ({ patientId }: Props) => {
  const { data: oncologyPatientData, isLoading } =
    useGetOncologyPatientDataQuery({ patientId });

  const generalFieldsToDisplay = useMemo<PatientOverviewEntry[]>(() => {
    if (!oncologyPatientData) {
      // We want to show all labels nonetheless, but we would show alert
      // instead of fields anyway with missing oncologyPatientData
      return [];
    }

    const {
      reference,
      sex,
      tumour_type,
      neoplastic_cellularity,
      assay,
      sample_type,
    } = oncologyPatientData;

    return [
      {
        key: "Patient ID",
        value: valueOrNA(reference),
        id: "sample_id",
      },
      {
        key: "Tumour Type",
        value:
          tumour_type?.code && tumour_type.name
            ? `${tumour_type.name} (${tumour_type.code})`
            : "N/A",
        id: "tumour_type",
      },
      {
        key: "Neoplastic Cellularity (Pathology Estimation %)",
        value: valueOrNA(neoplastic_cellularity),
        id: "neoplastic_cellularity",
      },
      {
        key: "Sex (Biological)",
        value: valueOrNA(sex),
        id: "sex",
      },
      {
        key: "Baitset",
        value: valueOrNA(assay?.name),
        id: "baitset",
      },
      {
        key: "Sample Type",
        value: valueOrNA(sample_type),
        id: "sample_type",
      },
    ];
  }, [oncologyPatientData]);

  const softwareVersionsToDisplay = useMemo<PatientOverviewEntry[]>(() => {
    if (!oncologyPatientData) {
      // We want to show all labels nonetheless, but we would show alert
      // instead of fields anyway with missing oncologyPatientData
      return [];
    }

    const { oncology_platform } = oncologyPatientData.software_versions;

    return [
      {
        key: "Oncology Platform",
        value: valueOrNA(oncology_platform),
        id: "oncology_platform",
      },
    ];
  }, [oncologyPatientData]);

  const metadataFieldsToDisplay = useMemo(() => {
    if (!oncologyPatientData) {
      // We want to show all labels nonetheless, but we would show alert
      // instead of fields anyway with missing oncologyPatientData
      return [];
    }

    const { metadata } = oncologyPatientData;

    return [
      {
        key: "Patient Name",
        value: valueOrNA(getMetadataFieldValue(metadata, "patient_name")),
        id: "patient_name",
      },
      {
        key: "Date Of Birth",
        value: valueOrNA(getMetadataFieldValue(metadata, "date_of_birth")),
        id: "date_of_birth",
      },
      {
        key: "NHS Number",
        value: valueOrNA(getMetadataFieldValue(metadata, "nhs_number")),
        id: "nhs_number",
      },
      {
        key: "Treating Clinician / Referring Clinician",
        value: valueOrNA(getMetadataFieldValue(metadata, "treating_clinician")),
        id: "treating_clinician",
      },
      {
        key: "Test Ordered By",
        value: valueOrNA(getMetadataFieldValue(metadata, "test_ordered_by")),
        id: "test_ordered_by",
      },
      {
        key: "Test Request Date / Referral Date",
        value: valueOrNA(getMetadataFieldValue(metadata, "test_request_date")),
        id: "test_request_date",
      },
      {
        key: "Biopsy Date",
        value: valueOrNA(getMetadataFieldValue(metadata, "biopsy_date_time")),
        id: "biopsy_date_time",
      },
      {
        key: "Biopsy Site / Hospital Number",
        value: valueOrNA(
          getMetadataFieldValue(metadata, "biopsy_site_hospital_number")
        ),
        id: "biopsy_site_hospital_number",
      },
      {
        key: "Case ID (Pathology)",
        value: valueOrNA(getMetadataFieldValue(metadata, "case_id_pathology")),
        id: "case_id_pathology",
      },
      {
        key: "Case ID (Molecular Dx)",
        value: valueOrNA(
          getMetadataFieldValue(metadata, "case_id_molecular_dx")
        ),
        id: "case_id_molecular_dx",
      },
      {
        key: "Specimen ID",
        value: valueOrNA(getMetadataFieldValue(metadata, "specimen_id")),
        id: "specimen_id",
      },
      {
        key: "Specimen Type",
        value: valueOrNA(getMetadataFieldValue(metadata, "specimen_type")),
        id: "specimen_type",
      },
      {
        key: "Pathology Block Number",
        value: valueOrNA(
          getMetadataFieldValue(metadata, "pathology_block_number")
        ),
        id: "pathology_block_number",
      },
      {
        key: "Requested Molecular Test",
        value: valueOrNA(
          getMetadataFieldValue(metadata, "requested_molecular_test")
        ),
        id: "requested_molecular_test",
      },
      {
        key: "Previous / Current Tumour-Related Treatment",
        value: valueOrNA(
          getMetadataFieldValue(metadata, "past_current_tumour_treatment")
        ),
        id: "past_current_tumour_treatment",
      },
    ];
  }, [oncologyPatientData]);

  if (isLoading) {
    return <Loading />;
  }

  if (!oncologyPatientData) {
    return (
      <Alert severity="error" icon>
        Patient data failed to load successfully. Please try again later.
      </Alert>
    );
  }

  return (
    <div className={styles["oncology-patient-overview"]}>
      <h3>Interpretation Request Information</h3>
      <Pane data={generalFieldsToDisplay} />
      <h3 className="mt-4">Additional Patient Information</h3>
      <Pane data={metadataFieldsToDisplay} />
      <h3 className="mt-4">Software Versions</h3>
      <Pane data={softwareVersionsToDisplay} />
      <div className={styles.actions}>
        <Download
          url={`/webapi/entities/oncology/patients/${patientId}/excel`}
          filename={`${patientId}_patient_information.xlsx`}
          caption="Download (Excel)"
          dataTestId="download-patient-information"
          context="default"
        />
      </div>
    </div>
  );
};

export default OncologyPatientOverview;
