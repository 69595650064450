import React, { memo, useMemo, useState } from "react";

import { OncologyCNVBrowser } from "modules/oncologyExplore/genoverse/OncologyCNVBrowser";

import {
  DriverClassificationCellProps,
  OncologyExploreComponentProps,
} from "../common-types";
import { CommonContainer } from "../components/CommonContainer";
import { DriverClassificationCell } from "../components/DriverClassificationCell";
import { getRowProps } from "../components/utils";

import styles from "./CopyNumberVariantsContainer.module.scss";

import { OncologyCNV } from "types/CNV";

const getColumns = (
  driverClassificationCellProps: DriverClassificationCellProps
) => [
  {
    Header: "Gene",
    accessor: "gene",
    id: "gene",
  },
  {
    Header: "Chromosome",
    accessor: "chrom",
    id: "chrom",
  },
  {
    Header: "Start",
    accessor: "start",
    id: "start",
  },
  {
    Header: "End",
    accessor: "end",
    id: "end",
  },
  {
    Header: "Gene Weight",
    accessor: "weight",
    id: "weight",
    sortType: "basic",
  },
  {
    Header: "Segment Weight",
    accessor: "segment_weight",
    id: "segment_weight",
    sortType: "basic",
  },
  {
    Header: "Gene Depth",
    accessor: "depth",
    id: "depth",
    sortType: "basic",
  },
  {
    // note about the naming it Bins vs Probes https://github.com/etal/cnvkit/pull/586 as suggested by BIX
    Header: "Gene Bins",
    accessor: "probes",
    id: "probes",
  },
  {
    // note about the naming it Bins vs Probes https://github.com/etal/cnvkit/pull/586 as suggested by BIX
    Header: "Segment Bins",
    accessor: "segment_probes",
    id: "segment_probes",
  },
  {
    Header: "Segment Log2",
    accessor: "log2",
    id: "log2",
    sortType: "basic",
  },
  {
    Header: "95% Confidence Interval Around Gene Log2",
    accessor: "ci_lo",
    id: "ci_hi-ci_lo",
    sortType: "basic",
    Cell: rowInfo => {
      const {
        row: {
          original: { ci_hi, ci_lo },
        },
      } = rowInfo;

      return `${ci_lo} - ${ci_hi}`;
    },
  },
  {
    Header: "Copy Number Change",
    accessor: "copy_number",
    id: "copy_number",
    sortType: "basic",
  },
  {
    Header: "Variant Type",
    accessor: "variant_type",
    id: "variant_type",
  },
  {
    Header: "Gene Type",
    accessor: "gene_type",
    id: "gene_type",
  },
  {
    Header: "Driver",
    disableFilters: true,
    accessor: "driver",
    Cell: DriverClassificationCell(driverClassificationCellProps),
    disableSortBy: true,
  },
];

interface Props extends OncologyExploreComponentProps {
  patientId: number;
  setGenomeBrowserShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const CopyNumberVariantsContainer: React.FC<Props> = ({
  patientId,
  data,
  variantDriverClassifications,
  setVariantDriverClassifications,
  reclassificationInProgress,
  setGenomeBrowserShown,
}) => {
  const [variantToBrowse, setVariantToBrowse] = useState<OncologyCNV | null>(
    null
  );

  const defaultSortBy = useMemo(() => [{ id: "gene", desc: false }], []);

  const columns = useMemo(
    () =>
      getColumns({
        variantDriverClassifications,
        setVariantDriverClassifications,
        reclassificationInProgress,
      }),
    [
      reclassificationInProgress,
      setVariantDriverClassifications,
      variantDriverClassifications,
    ]
  );

  const handleBackToVariantReclassification = () => {
    setVariantToBrowse(null);
    setGenomeBrowserShown(false);
  };

  const genomeBrowserNode = useMemo(
    () =>
      variantToBrowse && (
        <OncologyCNVBrowser
          patientId={patientId}
          startPosition={variantToBrowse.start}
          endPosition={variantToBrowse.end}
          chrom={variantToBrowse.chrom}
        />
      ),
    [variantToBrowse, patientId]
  );

  return (
    <>
      <CommonContainer
        titleSegment="copy number variants"
        data={variantToBrowse ? [variantToBrowse] : data}
        columns={columns}
        getRowProps={getRowProps(variantDriverClassifications, original => {
          setVariantToBrowse(original);
          setGenomeBrowserShown(true);
        })}
        className={styles.container}
        defaultSortBy={defaultSortBy}
        variantToBrowse={variantToBrowse}
        genomeBrowserNode={genomeBrowserNode}
        handleBackToVariantReclassification={
          handleBackToVariantReclassification
        }
      />
    </>
  );
};

export default memo(CopyNumberVariantsContainer);
