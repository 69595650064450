import Genoverse from "genoverse";

import GenoverseTracks from "modules/genoverse/GenoverseTracks";
import { Config } from "modules/genoverse/tracks/core/SequenceVariants";

import { flattenApiEntity } from "api/utils/common";

const { Patient } = GenoverseTracks;

export const insertPatientSNVFeature = (feature, colorMap) => {
  const { alt_allele } = feature;
  const colorProp = colorMap["Unknown consequence"].color;
  const params = {
    sequence: alt_allele,
    outlineColor: colorProp,
    color: colorProp,
    genoTypeColor: colorProp,
    legendColor: colorProp,
  };

  const { color } = colorMap["Unknown consequence"];

  return {
    ...feature,
    ...color,
    ...params,
  };
};

const valueOrNA = (value: any) => (value === 0 ? value : value || "N/A");

export const populatePatientSNVMenu = (feature, extras) => {
  const menu = {};
  let entries: Array<Array<string | number>> = [];

  if (feature.alt_allele || feature.alt_allele === "") {
    entries = [
      // values used here should be present hence not using valueOrNA
      ["title", `${feature.gene} ${feature.coding_change}`],
      // values used here should be present hence not using valueOrNA
      [
        "Location",
        feature.ref_allele.length === 1
          ? feature.chr + ":" + feature.start
          : feature.chr +
            ":" +
            feature.start +
            "-" +
            (Number(feature.start) + feature.ref_allele.length - 1),
      ],
      ["Variant Type", valueOrNA(feature.variant_type)],
      ["Gene Type", valueOrNA(feature.gene_type)],
      ["Protein Change", valueOrNA(feature.protein_change)],
      ["Coding Change", valueOrNA(feature.coding_change)],
      ["Protein position", valueOrNA(feature.protein_position)],
      ["Depth", valueOrNA(feature.depth)],
    ];
  }

  for (const [title, value] of entries) {
    if (value) {
      menu[title] = valueOrNA(value);
    }
  }

  return { ...menu, ...extras };
};

export const OncologyPatientSNVTrackWrapper = config =>
  Patient(config).extend({
    trackKey: "oncologyPatientSNV",
    legendTrack: false,
    url: `/webapi/entities/patients/${config.patientId}/oncology/genoverse/variants/snvs?filter[chr]=__CHR__&filter[start]=__START__&filter[end]=__END__`,
    populateMenu(feature, extras) {
      return populatePatientSNVMenu(feature, extras);
    },
    parseData(data, chr, start, end) {
      this.base(data.data.map(flattenApiEntity), chr, start, end);
    },
    insertFeature(feature) {
      this.base(insertPatientSNVFeature(feature, this.prop("colorMap")));
    },
    controller: Genoverse.Track.Controller.extend(Config.controller),
  });
