import React, { memo, useMemo, useState } from "react";

import {
  DriverClassificationCellProps,
  OncologyExploreComponentProps,
} from "../common-types";
import { CommonContainer } from "../components/CommonContainer";
import { DriverClassificationCell } from "../components/DriverClassificationCell";
import { getRowProps } from "../components/utils";
import { OncologySNVBrowser } from "../genoverse/OncologySNVBrowser";

import styles from "./SequenceVariantsContainer.module.scss";

export const FILTER_PLACEHOLDER = "Filter by Gene...";

const getColumns = (
  driverClassificationCellProps: DriverClassificationCellProps
) => [
  {
    Header: "Gene",
    accessor: "gene",
    id: "gene",
  },
  {
    Header: "Chromosome",
    accessor: "chrom",
    id: "chrom",
  },
  {
    Header: "Position",
    accessor: "pos",
    id: "pos",
  },
  {
    Header: "Ref",
    accessor: "ref",
    id: "ref",
  },
  {
    Header: "Alt",
    accessor: "alt",
    id: "alt",
  },
  {
    Header: "VAF",
    accessor: "vaf",
    id: "vaf",
    sortType: "basic",
  },
  {
    Header: "Coding Change",
    accessor: "coding_change",
    id: "coding_change",
  },
  {
    Header: "Protein Change",
    accessor: "protein_change",
    id: "protein_change",
  },
  {
    Header: "Variant Type",
    accessor: "variant_type",
    id: "variant_type",
  },
  {
    Header: "Gene Type",
    accessor: "gene_type",
    id: "gene_type",
  },
  {
    Header: "GENIE score - missense",
    accessor: "genie_score_missense",
    id: "genie_score_missense",
  },
  {
    Header: "GENIE score - inframe",
    accessor: "genie_score_inframe",
    id: "genie_score_inframe",
  },
  {
    Header: "Driver",
    disableFilters: true,
    accessor: "driver",
    Cell: DriverClassificationCell(driverClassificationCellProps),
    onClick: (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
    },
    className: styles.driver,
    disableSortBy: true,
  },
];

interface Props extends OncologyExploreComponentProps {
  patientId: number;
  setGenomeBrowserShown: React.Dispatch<React.SetStateAction<boolean>>;
}

const SequenceVariantsContainer: React.FC<Props> = ({
  patientId,
  data,
  variantDriverClassifications,
  setVariantDriverClassifications,
  reclassificationInProgress,
  setGenomeBrowserShown,
}) => {
  const [variantToBrowse, setVariantToBrowse] = useState<OncologySNV | null>(
    null
  );

  const defaultSortBy = useMemo(() => [{ id: "gene", desc: false }], []);

  const columns = useMemo(
    () =>
      getColumns({
        variantDriverClassifications,
        setVariantDriverClassifications,
        reclassificationInProgress,
      }),
    [
      reclassificationInProgress,
      setVariantDriverClassifications,
      variantDriverClassifications,
    ]
  );

  const handleBackToVariantReclassification = () => {
    setVariantToBrowse(null);
    setGenomeBrowserShown(false);
  };

  const genomeBrowserNode = useMemo(
    () =>
      variantToBrowse && (
        <OncologySNVBrowser
          patientId={patientId}
          pos={variantToBrowse.pos}
          chrom={variantToBrowse.chrom}
        />
      ),
    [variantToBrowse, patientId]
  );

  return (
    <>
      <CommonContainer
        titleSegment="sequence variants"
        data={variantToBrowse ? [variantToBrowse] : data}
        columns={columns}
        getRowProps={getRowProps(variantDriverClassifications, original => {
          setVariantToBrowse(original);
          setGenomeBrowserShown(true);
        })}
        className={styles.container}
        defaultSortBy={defaultSortBy}
        variantToBrowse={variantToBrowse}
        genomeBrowserNode={genomeBrowserNode}
        handleBackToVariantReclassification={
          handleBackToVariantReclassification
        }
      />
    </>
  );
};

export default memo(SequenceVariantsContainer);
