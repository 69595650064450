import { FC, useEffect, useMemo, useState } from "react";

import {
  Dropdown,
  Icon,
  Link,
  FormGroup,
  Label,
  FormFieldDescription,
  Input,
} from "pattern-library";

import { useDebounce } from "common/utils";
import AssayQcRow from "modules/oncologyQC/AssayQcRow";
import { DNA_ASSAY_QC_FIELDS_CONFIG } from "modules/oncologyQC/constants";
import {
  getGeneCoverageQcByThreshold,
  isOncologyQcDataOutOfRange,
} from "modules/oncologyQC/utils";
import { toDecimalPlaces } from "modules/utils/format";

import { OncologyDnaQcData } from "types/oncologyQc";

const DEBOUNCE_DELAY = 400;
const FULL_COVERAGE = 100;

interface Props {
  dnaQcData: OncologyDnaQcData;
}

const DnaQc: FC<Props> = ({ dnaQcData }) => {
  const [pctCovered, setPctCovered] = useState<number>(FULL_COVERAGE);
  const [geneCoverageQc, setGeneCoverageQc] = useState<
    OncologyDnaQcData["gene_coverage_qc"][number]["genes"]
  >([]);
  const debouncedPctCovered = useDebounce(pctCovered, DEBOUNCE_DELAY);

  const [coverageThreshold, setCoverageThreshold] = useState<number>();
  // 'Coverage threshold' dropdown options
  const [coverageThresholds, setCoverageThresholds] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    if (dnaQcData.gene_coverage_qc) {
      setCoverageThresholds(
        dnaQcData.gene_coverage_qc.reduce((acc, { coverage_threshold }) => {
          acc[coverage_threshold] = coverage_threshold;
          return acc;
        }, {})
      );
    }
  }, [dnaQcData.gene_coverage_qc]);

  useEffect(() => {
    if (coverageThresholds.hasOwnProperty("100")) {
      setCoverageThreshold(100);
    } else {
      const values: Array<any> = Object.values(coverageThresholds);

      if (values.length > 0) {
        setCoverageThreshold(values[0]);
      }
    }
  }, [coverageThresholds]);

  useEffect(() => {
    if (dnaQcData.gene_coverage_qc) {
      const geneCoverageQcByThreshold = getGeneCoverageQcByThreshold(
        debouncedPctCovered,
        dnaQcData.gene_coverage_qc,
        coverageThreshold
      );
      setGeneCoverageQc(geneCoverageQcByThreshold);
    }
  }, [coverageThreshold, debouncedPctCovered, dnaQcData.gene_coverage_qc]);

  const onPctCoveredChange = ({ target: { value } }) => {
    if (value > 0 && value <= FULL_COVERAGE) setPctCovered(value);
  };

  const onCoverageThresholdChange = ({ target: { value } }) => {
    setCoverageThreshold(Number.parseInt(value));
  };

  const showWarning = useMemo<boolean>(
    () =>
      Boolean(
        isOncologyQcDataOutOfRange(dnaQcData, DNA_ASSAY_QC_FIELDS_CONFIG)
      ),
    [dnaQcData]
  );

  return (
    <div className="qc__oncology container">
      <div className="row">
        <div className="col-md-6">
          <h1>Assay QC</h1>
          <table className="qc__oncology__json_table table table-striped mt-4">
            <tbody>
              {DNA_ASSAY_QC_FIELDS_CONFIG.map(fieldConfig => (
                <AssayQcRow
                  key={fieldConfig.field}
                  value={dnaQcData[fieldConfig.field]}
                  {...fieldConfig}
                />
              ))}
            </tbody>
          </table>
          {showWarning && (
            <div
              data-testid="qc__oncology__json_warning"
              className="qc__oncology__json_warning"
            >
              <Icon
                className="qc__oncology__json_warning_icon"
                type="warning"
              />
              QC metrics are outside recommended range. See the
              <Link
                className="qc__oncology__json_table__warning__link"
                target="_blank"
                href="/user-guide/oncology/row/en-us/Default.htm#Oncology/5%20Reviewing%20patient%20data/Reviewing%20oncology%20QC%20data.htm%3FTocPath%3DReviewing%2520patient%2520data%7C_____2"
              >
                user guide
              </Link>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <h1>Genomic feature coverage QC</h1>
          <FormGroup className="row mt-4">
            <Label htmlFor="coverageThreshold" className="col-sm-3">
              Coverage threshold
            </Label>
            <div className="col-sm-6">
              <Dropdown
                id="coverageThreshold"
                data-testid="coverageThreshold"
                options={coverageThresholds}
                value={coverageThreshold}
                onChange={onCoverageThresholdChange}
              />
              <FormFieldDescription description="Coverage Threshold is the minimum depth of coverage required" />
            </div>
          </FormGroup>
          <FormGroup className="row mt-3">
            <Label htmlFor="threshold" className="col-sm-3">
              Percent Covered
            </Label>
            <div id="threshold" className="col-sm-6">
              <div className="input-group">
                <span className="input-group-addon">&lt;</span>
                <Input
                  type="number"
                  value={pctCovered}
                  onChange={onPctCoveredChange}
                />
              </div>
              <FormFieldDescription description="The percentage of the genomic footprint that must be covered to at least the specified coverage threshold" />
            </div>
          </FormGroup>

          <h5 className="mt-4">
            The following genes had less than {pctCovered}% coverage at the
            specified depth
          </h5>
          <table className="qc__oncology__json_table qc__oncology__json_table__fixed table table-striped">
            <thead>
              <tr>
                <th>Gene</th>
                <th>{`% covered${
                  coverageThreshold ? ` to at least ${coverageThreshold}x` : ""
                }`}</th>
              </tr>
            </thead>
            <tbody>
              {geneCoverageQc.map(({ gene, covered_pct }) => (
                <tr key={gene}>
                  <th>{gene}</th>
                  <td data-testid={`gene-${gene}`}>
                    {toDecimalPlaces(covered_pct, 1, "down")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6" />
        <small className="text-muted col-md-6">
          Note that deletions, particularly homozygous deletions and/or
          deletions in high cellularity samples, can cause low coverage.
        </small>
      </div>
    </div>
  );
};

export default DnaQc;
