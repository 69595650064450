import GenoverseTracks from "modules/genoverse/GenoverseTracks";

import { flattenApiEntity } from "api/utils/common";

const { StructuralVariants } = GenoverseTracks;

interface SVFeatureData {
  chr: string;
  end: number;
  id: string; // jsonapi
  sort: number;
  start: number;
  subtype: "CNV gain" | "CNV loss";
  type: string; // jsonapi
}

export const insertPatientSVFeature = (feature: SVFeatureData) => {
  const { start, end, subtype } = feature;

  const length = end - start;

  return {
    ...feature,
    variant: {
      start,
      end,
      subtype,
      length: length || undefined, // in case for some reason we don't have end or start and then length is NaN
    },
    label: subtype,
    legend: subtype,
  };
};

export const OncologyPatientSVTrackWrapper = config =>
  StructuralVariants(config).extend({
    trackKey: "oncologyPatientSV",
    legendTrack: false,
    legend: false,
    url: `/webapi/entities/patients/${config.patientId}/oncology/genoverse/variants/svs?filter[chr]=__CHR__&filter[start]=__START__&filter[end]=__END__`,
    parseData(data, chr, start, end) {
      this.base(data.data.map(flattenApiEntity), chr, start, end);
    },
    insertFeature(feature: SVFeatureData) {
      // match to what StructuralVariants we extend expects
      this.base(insertPatientSVFeature(feature));
    },
  });
